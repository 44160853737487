import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import StyleContext from "../../../stores/styles/StyleContext";
import { IBreadcrumbItem } from "../../../types/breadcrumb";

interface BreadcrumbProps {
  dataTestname: string;
  breadcrumbs: IBreadcrumbItem[];
}
const Breadcrumbs: React.FC<BreadcrumbProps> = ({
  dataTestname,
  breadcrumbs,
}) => {
  const navigate = useNavigate();

  const handleCrumbClick = (url: string) => {
    navigate(url);
  };

  const styleContext = useContext(StyleContext);

  return (
    <StyledCrumbContainer data-testname={dataTestname}>
      <StyledUl>
        {breadcrumbs.map((crumb, index) => (
          <StyledBreadcrumbSpan key={index}>
            {index > 0 && !styleContext.isMobile && (
              <StyledSeparatorLi elements={breadcrumbs.length}>
                <StyledSeparatorContainer>
                  <StyledSeparator>{">"}</StyledSeparator>
                </StyledSeparatorContainer>
              </StyledSeparatorLi>
            )}
            {styleContext.isMobile && index === breadcrumbs.length - 1 && (
              <StyledSeparatorLi elements={breadcrumbs.length}>
                <StyledSeparatorContainer>
                  <StyledMobileSeparator>{"<"}</StyledMobileSeparator>
                </StyledSeparatorContainer>
              </StyledSeparatorLi>
            )}
            <StyledLi
              data-testname={`breadcrumb-${crumb.id}`}
              onClick={(e) => handleCrumbClick(crumb.url)}
              elements={breadcrumbs.length}
            >
              {((styleContext.isMobile && index === breadcrumbs.length - 1) ||
                !styleContext.isMobile) && (
                <StyledCrumb data-testid="crumb-title">
                  <StyledPrefix>{crumb.prefix}: </StyledPrefix>
                  {crumb.title}
                </StyledCrumb>
              )}
            </StyledLi>
          </StyledBreadcrumbSpan>
        ))}
      </StyledUl>
    </StyledCrumbContainer>
  );
};

export default Breadcrumbs;

const StyledCrumbContainer = styled.div`
  display: flex;
  height: 33px;
`;

const StyledBreadcrumbSpan = styled.span`
  display: contents;
`;

const StyledUl = styled.ul`
  padding-left: 0px;
  list-style: none;
  display: flex;
  margin-top: 6px;
  margin-bottom: 10px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding-left: 8px;
  }
`;

interface LiProps {
  elements: number;
}

const StyledLi = styled.li<LiProps>`
  width: max-content;
  min-width: unset;

  display: flex;
  font-size: ${(props) => props.theme.breadcrumbSize};
  font-weight: ${(props) => props.theme.breadcrumbWeight};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: max-content;
    min-width: unset;
  }
`;

const StyledCrumb = styled.a`
  color: #9c1b30;
  text-decoration: none;
  padding-right: 0px;
  padding-left: 0px;
  font-size: ${(props) => props.theme.breadcrumbSize};
  font-weight: ${(props) => props.theme.breadcrumbWeight};
  font-style: italic;
  text-align: left;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }

  span {
    font-style: normal;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const StyledPrefix = styled.span`
  display: inline;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: inline;
  }
`;

const StyledSeparatorLi = styled.li<LiProps>`
  display: flex;
  font-size: ${(props) => props.theme.breadcrumbSize};
  font-weight: ${(props) => props.theme.breadcrumbWeight};
`;

const StyledSeparator = styled.a`
  color: #9c1b30;
  text-decoration: none;
  width: 7px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: ${(props) => props.theme.breadcrumbSize};
  font-weight: ${(props) => props.theme.breadcrumbWeight};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-style: normal;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    text-align: center;
    padding-right: 0px;
    padding-left: 0px;
  }
`;

const StyledMobileSeparator = styled.a`
  color: #9c1b30;
  text-decoration: none;
  width: 7px;
  padding-right: 10px;
  padding-left: 0px;
  font-size: ${(props) => props.theme.breadcrumbSize};
  font-weight: ${(props) => props.theme.breadcrumbWeight};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-style: normal;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    text-align: center;
    padding-right: 0px;
    padding-left: 0px;
  }
`;

const StyledSeparatorContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
