import {
  Configuration,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "api://19a2f823-f75a-4ee4-ab2f-b49db5520d55",
    authority:
      "https://login.microsoftonline.com/96ddf04f-9760-469e-aadd-244f6a047ed6",
    redirectUri: process.env.REACT_APP_PORTAL_URL,
    postLogoutRedirectUri: process.env.REACT_APP_PORTAL_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: PopupRequest = {
  scopes: ["api://19a2f823-f75a-4ee4-ab2f-b49db5520d55/access_as_user"],
};

export const loginRedirectRequest: RedirectRequest = {
  scopes: ["api://19a2f823-f75a-4ee4-ab2f-b49db5520d55/access_as_user"],
};

// this is used to access files that pertain to the user
// export const apiRequest : PopupRequest = {
//   scopes: [""]
// }

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
