import { createContext } from "react";
import { IStyleContext } from "./types";

const defaultStyleContext: IStyleContext = {
  loading: true,
  isMobile: true,
};

const StyleContext = createContext<IStyleContext>(defaultStyleContext);

export default StyleContext;
