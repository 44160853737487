import React from "react";
import styled from "styled-components";
import ProfireButton from "../buttons/ProfireButton";
import Loading from "./Loading";

interface ConfirmationBoxProps {
  dataTestname: string;
  message: string;
  heading: string;
  onOk: React.MouseEventHandler<HTMLButtonElement>;
  onYes?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  active: boolean;
  displayCancel: boolean;
  finalDisplay?: boolean;
}

const ConfirmationBox: React.FC<ConfirmationBoxProps> = ({
  dataTestname,
  message,
  heading,
  onOk,
  onYes,
  onCancel,
  active,
  displayCancel,
  finalDisplay,
}) => {
  return (
    <StyledContainer
      data-testname={dataTestname}
      data-testid="container"
      active={active}
    >
      <StyledMainDiv>
        <StyledHeader>
          <p>{heading}</p>
        </StyledHeader>
        <StyledInnerContainer>
          {message === "" ? (
            <Loading dataTestname="confirmation-loading" />
          ) : (
            <StyledMessageContainer>
              <StyledMessage data-testid="message">{message}</StyledMessage>
            </StyledMessageContainer>
          )}
          {(finalDisplay || !displayCancel) && message !== "" ? (
            <StyledButtonContainer>
              <StyledButtonDiv>
                <ProfireButton
                  dataTestname="confirmation-box-confirm-button"
                  text="Ok"
                  onClickFunction={onOk}
                  size={2}
                />
              </StyledButtonDiv>
            </StyledButtonContainer>
          ) : displayCancel && message !== "" ? (
            <StyledButtonContainer>
              <StyledButtonDiv>
                <ProfireButton
                  dataTestname="confirmation-box-cancel-button"
                  text="Cancel"
                  onClickFunction={onCancel}
                  size={2}
                />
              </StyledButtonDiv>
              <StyledButtonDiv>
                <ProfireButton
                  dataTestname="confirmation-box-confirm-button"
                  text="Yes"
                  onClickFunction={onYes}
                  size={2}
                />
              </StyledButtonDiv>
            </StyledButtonContainer>
          ) : (
            <></>
          )}
        </StyledInnerContainer>
      </StyledMainDiv>
    </StyledContainer>
  );
};

export default ConfirmationBox;

interface StyledContainerProps {
  active: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  height: 100%;
  width: 100%;
  background-color: rgba(44, 44, 44, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
`;

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  min-height: 172px;
  width: Calc(100% - 20px);
  margin: auto;
  position: relative;
`;

const StyledHeader = styled.div`
  background-color: #1f1f1f;
  width: calc(100% - 40px);
  border-radius: 6px 6px 0 0;
  font-weight: 600;
  padding: 6px 20px 6px 20px;
  font-size: ${(props) => props.theme.headingMainSize};
  font-weight: ${(props) => props.theme.headingMainWeight};

  p {
    color: #ededed;
    text-align: left;
    line-height: 24px;
    margin: 0;
  }
`;

const StyledInnerContainer = styled.div`
  width: calc(100% - 40px);
  max-width: 480px;
  min-height: 96px;
  text-align: center;
  background-color: white;
  border-radius: 0 0 6px 6px;
  position: relative;
  padding: 17px 20px 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledMessageContainer = styled.div`
  width: 100%;
  display: flex;
`;

const StyledMessage = styled.p`
  width: 100%;
  margin: 0;
  color: #0a0a0a;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.confirmationBoxMessageWeight};
  white-space: pre-wrap;
  text-align: left;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    font-size: ${(props) => props.theme.confirmationBoxMessageSize};
    font-weight: ${(props) => props.theme.confirmationBoxMessageWeight};
  }
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledButtonDiv = styled.div`
  margin-left: 20px;
`;
