import styled from "styled-components";
import InfoIcon from "../../../assets/images/clickables/info-button.svg";

interface InfoButtonProps {
  dataTestname: string;
  onClickFunction?: React.MouseEventHandler<HTMLDivElement>;
  hoverText?: string;
  hoverPositionMobile: string[];
  hoverPositionTablet?: string[];
  hoverPositionDesktop?: string[];
  hoverWidthsMobile: string[]; //min-width, width, max-width
  hoverWidthsTablet?: string[];
  hoverWidthsDesktop?: string[];
  mobileHoverTextRef?: any;
}

const InfoButton: React.FC<InfoButtonProps> = ({
  dataTestname,
  onClickFunction = undefined,
  hoverText,
  hoverPositionMobile,
  hoverPositionTablet = hoverPositionMobile,
  hoverPositionDesktop = hoverPositionMobile,
  hoverWidthsMobile,
  hoverWidthsTablet = hoverWidthsMobile,
  hoverWidthsDesktop = hoverWidthsMobile,
  mobileHoverTextRef,
}) => {
  return (
    <StyledInfoButtonDiv
      data-testname={dataTestname}
      onClick={onClickFunction}
      data-testid="info-button"
      data-hover="Test hover text"
      hoverText={hoverText}
      hoverPositionMobile={hoverPositionMobile}
      hoverPositionTablet={hoverPositionTablet}
      hoverPositionDesktop={hoverPositionDesktop}
      hoverWidthsMobile={hoverWidthsMobile}
      hoverWidthsTablet={hoverWidthsTablet}
      hoverWidthsDesktop={hoverWidthsDesktop}
      ref={mobileHoverTextRef}
    >
      <img src={InfoIcon} alt="info button" />
    </StyledInfoButtonDiv>
  );
};

export default InfoButton;

interface DivProps {
  hoverText?: string;
  hoverPositionMobile: string[];
  hoverPositionTablet?: string[];
  hoverPositionDesktop?: string[];
  hoverWidthsMobile: string[];
  hoverWidthsTablet?: string[];
  hoverWidthsDesktop?: string[];
}

const StyledInfoButtonDiv = styled.div<DivProps>`
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
  position: relative;

  :hover {
    cursor: ${(props) => (props.hoverText ? "cursor" : "pointer")};
  }

  :before {
    content: "${(props) => props.hoverText}";
    visibility: hidden;
    opacity: 0;
    min-width: ${(props) => props.hoverWidthsMobile[0]};
    width: ${(props) => props.hoverWidthsMobile[1]};
    background-color: #aaa;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 10px;
    transition: opacity 0.5s ease-in-out;
    text-align: left;
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};

    position: absolute;
    z-index: 130;
    top: ${(props) => props.hoverPositionMobile[0]};
    right: ${(props) => props.hoverPositionMobile[1]};
    bottom: ${(props) => props.hoverPositionMobile[2]};
    left: ${(props) => props.hoverPositionMobile[3]};
  }

  :hover:before {
    opacity: 1;
    /* visibility: ${(props) => (props.hoverText ? "visible" : "hidden")}; */
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 20px;
    height: 20px;
    margin-bottom: 0px;
    position: relative;

    :hover {
      cursor: ${(props) => (props.hoverText ? "cursor" : "pointer")};
    }

    :before {
      content: "${(props) => props.hoverText}";
      visibility: hidden;
      opacity: 0;
      min-width: ${(props) =>
        props.hoverWidthsDesktop ? props.hoverWidthsDesktop[0] : "unset"};
      width: ${(props) =>
        props.hoverWidthsDesktop ? props.hoverWidthsDesktop[1] : "unset"};
      background-color: #aaa;
      color: #fff;
      text-align: center;
      border-radius: 3px;
      padding: 10px;
      transition: opacity 0.5s ease-in-out;
      text-align: left;
      font-size: ${(props) => props.theme.contentMainSize};
      font-weight: ${(props) => props.theme.contentMainWeight};

      position: absolute;
      z-index: 130;
      top: ${(props) =>
        props.hoverPositionDesktop ? props.hoverPositionDesktop[0] : "unset"};
      right: ${(props) =>
        props.hoverPositionDesktop ? props.hoverPositionDesktop[1] : "unset"};
      bottom: ${(props) =>
        props.hoverPositionDesktop ? props.hoverPositionDesktop[2] : "unset"};
      left: ${(props) =>
        props.hoverPositionDesktop ? props.hoverPositionDesktop[3] : "unset"};
    }

    :hover:before {
      opacity: 1;
      visibility: ${(props) => (props.hoverText ? "visible" : "hidden")};
    }
  }
`;
