import styled from "styled-components";
import XButton from "../../../assets/images/clickables/x-button.svg";

interface DefaultContainerProps {
  dataTestname: string;
  title: string;
  darkBackground: boolean;
  children?: any;
  showX?: boolean;
  xFunction?: React.MouseEventHandler<HTMLImageElement>;
  modalMaxHeight?: string;
  insideModal?: boolean;
  mobileRedLine?: boolean;
  overwritePadding?: string;
  overwriteWidth?: string;
}
const DefaultContainer: React.FC<DefaultContainerProps> = ({
  dataTestname,
  title,
  darkBackground,
  children,
  showX,
  xFunction,
  modalMaxHeight,
  insideModal,
  mobileRedLine = false,
  overwritePadding,
  overwriteWidth,
}) => {
  return (
    <StyledDefaultContainer
      data-testname={dataTestname}
      modalMaxHeight={modalMaxHeight!}
      insideModal={insideModal!}
      overwritePadding={overwritePadding}
      overwriteWidth={overwriteWidth}
    >
      <StyledContainerTitle
        darkBackground={darkBackground}
        data-testid="container-title"
        mobileRedLine={mobileRedLine}
        insideModal={insideModal!}
      >
        {title}
      </StyledContainerTitle>
      {showX && (
        <StyledXButton
          darkBackground={darkBackground}
          src={XButton}
          alt="x button"
          onClick={xFunction}
          insideModal={insideModal!}
        />
      )}
      {children}
    </StyledDefaultContainer>
  );
};
export default DefaultContainer;

interface OuterDivProps {
  insideModal?: boolean;
  modalMaxHeight?: string;
  overwritePadding?: string;
  overwriteWidth?: string;
}
const StyledDefaultContainer = styled.div<OuterDivProps>`
  width: ${(props) =>
    props.overwriteWidth !== undefined
      ? props.overwriteWidth
      : "calc(100% - 20px)"};
  max-width: 1436px;
  background-color: ${(props) => props.theme.containerBgColor};
  padding: ${(props) =>
    props.overwritePadding !== undefined
      ? props.overwritePadding
      : props.insideModal === true
      ? "55px 10px 20px 10px"
      : "35px 10px 20px 10px"};
  border-radius: 4px;
  margin-bottom: 10px;
  position: relative;
  height: ${(props) =>
    props.insideModal === true ? "Calc(100% - 75px)" : "unset"};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: ${(props) =>
      props.overwriteWidth !== undefined
        ? props.overwriteWidth
        : "calc(100% - 40px)"};
    padding: ${(props) =>
      props.overwritePadding !== undefined
        ? props.overwritePadding
        : "55px 20px 20px 20px"};
    margin-bottom: ${(props) => props.theme.generalVerticalMargin};
  }
`;

interface ContainerTitleProps {
  darkBackground: boolean;
  mobileRedLine?: boolean;
  insideModal?: boolean;
}
const StyledContainerTitle = styled.p<ContainerTitleProps>`
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  height: 28px;
  margin: 0;
  border-radius: 4px 4px 0 0;
  font-weight: 600;
  color: #292929;
  text-align: left;
  line-height: 27px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: ${(props) =>
    props.mobileRedLine ? "1px solid #9C1B30" : "none"};

  padding: ${(props) =>
    props.insideModal &&
    (props.darkBackground ? "6px 10px 6px" : "15px 0px 6px")};
  background-color: ${(props) =>
    props.insideModal && (props.darkBackground ? "#1f1f1f;" : "white")};
  color: ${(props) =>
    props.insideModal && (props.darkBackground ? "#ededed" : "#292929")};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: calc(100% - 40px);
    background-color: ${(props) =>
      props.darkBackground ? "#1f1f1f;" : "white"};
    color: ${(props) => (props.darkBackground ? "#ededed" : "#292929")};
    padding: ${(props) => (props.darkBackground ? "6px" : "15px")} 20px 6px;
    font-weight: 600;
  }

  font-size: ${(props) => props.theme.headingMainSize};
`;

const StyledXButton = styled.img<ContainerTitleProps>`
  background-color: ${(props) => (props.darkBackground ? "#1f1f1f;" : "white")};
  position: absolute;
  top: Calc(13px - 6px);
  right: 11px;
  width: 18px;
  height: 25px;
  margin: 0;
  border-radius: 4px 4px 0 0;
  font-size: ${(props) => props.theme.headingMainSize};
  font-weight: 600;
  color: ${(props) => (props.darkBackground ? "#ededed" : "#292929")};
  text-align: left;
  line-height: 27px;
  :hover {
    cursor: pointer;
  }
`;
