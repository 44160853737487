import { ClipLoader } from "react-spinners";
import styled from "styled-components";

interface LoadingProps {
  dataTestname: string;
}

const Loading: React.FC<LoadingProps> = ({ dataTestname }) => {
  return (
    <StyledDiv data-testname={dataTestname} data-testid="loader-container">
      <ClipLoader color="#9c1b30" />
    </StyledDiv>
  );
};

export default Loading;

const StyledDiv = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;
