import styled from "styled-components";
import { useNavigate, useLocation } from "react-router";
import ProfireButton from "../components/common/buttons/ProfireButton";

const PageNotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleHomeKeyPress = () => {
    navigate("/");
  };

  return (
    <Styled404Container>
      <Styled404Div>
        <StyledTitle data-testid="heading-text">Page Not Found</StyledTitle>
        <StyledText data-testid="sub-heading-text">
          The page you are trying to navigate to does not exist.
        </StyledText>
        <StyledButtonDiv>
          <ProfireButton
            onClickFunction={handleHomeKeyPress}
            dataTestname="home-button"
            text="Home"
          />
        </StyledButtonDiv>
      </Styled404Div>
      <LocationTestDiv data-testid="location">
        {location.pathname}
      </LocationTestDiv>
    </Styled404Container>
  );
};

export default PageNotFound;

const Styled404Container = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 60px 16px 20px 16px;
  height: calc(100vh - 80px);
  height: calc(100dvh - 80px);
  background-color: white;
  display: flex;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 60px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const LocationTestDiv = styled.div`
  display: none;
`;

const StyledButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
`;

const Styled404Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const StyledTitle = styled.h1`
  margin-top: 0px;
  font-weight: 600;
`;

const StyledText = styled.h2`
  font-weight: 600;
`;
