import styled from "styled-components";

interface UserOptionProps {
  dataTestname: string;
  text: string;
  icon: string;
  onClickFunction: () => void;
}

const UserOption: React.FC<UserOptionProps> = ({
  dataTestname,
  text,
  icon,
  onClickFunction,
}) => {
  return (
    <StyledOptionDiv
      onClick={onClickFunction}
      data-testname={dataTestname}
      data-testid="main-user-option-div"
    >
      <img src={icon} alt={text} data-testid="image" />
      <p data-testid="text">{text}</p>
    </StyledOptionDiv>
  );
};

export default UserOption;

const StyledOptionDiv = styled.div`
  height: 30px;
  width: 100%;
  border-top: 1.5px solid #00000019;
  display: flex;
  justify-content: start;

  img {
    margin: 0;
    margin-left: 13px;
  }

  p {
    flex-basis: calc(100% - 30px);
    margin: 0 0 0 -3px;
    line-height: 30px;
    text-align: left;
    font-size: ${(props) => props.theme.userOptionsSmallText};
    font-weight: ${(props) => props.theme.contentMainWeight};
    color: #888a8d;
  }

  :hover {
    cursor: pointer;
    background-color: #f4f4f4;
  }
`;
