import React from "react";
import styled from "styled-components";
import DefaultContainer from "../containers/DefaultContainer";

interface ModalBoxProps {
  dataTestname: string;
  active: boolean;
  containerTitle: string;
  children?: any;
  showX?: boolean;
  xFunction?: React.MouseEventHandler<HTMLImageElement>;
  desktopContainerMinWidth: string;
  mobileContainerMinWidth: string;
  maxHeight?: string;
}

const ModalBox: React.FC<ModalBoxProps> = ({
  dataTestname,
  active,
  containerTitle,
  children,
  showX,
  xFunction,
  desktopContainerMinWidth,
  mobileContainerMinWidth,
  maxHeight,
}) => {
  return (
    <StyledContainer data-testid="container" active={active}>
      <StyledInnerContainer
        desktopMinWidth={desktopContainerMinWidth}
        mobileMinWidth={mobileContainerMinWidth}
      >
        <DefaultContainer
          dataTestname="modal-box-default-container"
          darkBackground={true}
          title={containerTitle}
          showX={showX}
          xFunction={xFunction}
          modalMaxHeight={maxHeight!}
          insideModal={true}
        >
          {children}
        </DefaultContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default ModalBox;

interface StyledContainerProps {
  active: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  height: 100%;
  width: 100%;
  background-color: rgba(44, 44, 44, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
  flex-direction: column;
  justify-content: center;
`;

interface StyledInnerContainerProps {
  desktopMinWidth: string;
  mobileMinWidth: string;
}

const StyledInnerContainer = styled.div<StyledInnerContainerProps>`
  min-width: ${(props) => props.mobileMinWidth};
  width: Calc(100% - 20px);
  text-align: center;
  background-color: white;
  border-radius: 5px;
  margin: 0 auto;
  max-height: 80%;

  label,
  h2 {
    text-align: left;
  }

  > div {
    margin-bottom: 0px;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: min-content;
    min-width: ${(props) => props.desktopMinWidth};
  }
`;
