import styled from "styled-components";
import EditIcon from "../../../assets/images/clickables/edit-button.svg";

interface EditButtonProps {
  dataTestname: string;
  onClickFunction: React.MouseEventHandler<HTMLDivElement>;
}

const EditButton: React.FC<EditButtonProps> = ({
  dataTestname,
  onClickFunction,
}) => {
  return (
    <StyledEditButtonDiv
      data-testname={dataTestname}
      onClick={onClickFunction}
      data-testid="edit-button"
    >
      <img src={EditIcon} alt="edit button" />
    </StyledEditButtonDiv>
  );
};

export default EditButton;

const StyledEditButtonDiv = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 13px;
  position: relative;

  :hover {
    cursor: pointer;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    :before {
      content: "Edit";
      visibility: hidden;
      opacity: 0;
      width: 60px;
      background-color: #aaa;
      color: #fff;
      text-align: center;
      border-radius: 3px;
      padding: 2px;
      transition: opacity 0.5s ease-in-out;

      position: absolute;
      z-index: 111;
      left: -45px;
      top: -28px;
    }

    :hover:before {
      opacity: 1;
      visibility: visible;
    }
  }
`;
