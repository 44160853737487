import styled from "styled-components";
import { useLocation, useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";

const PageUnauthorized = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();

  const [loggedIn] = useState<boolean>(accounts[0] !== undefined);
  const handleHomeKeyPress = async () => {
    if (loggedIn) {
      await localStorage.clear();
      await instance.logoutPopup({
        account: accounts[0],
        mainWindowRedirectUri: "/",
        postLogoutRedirectUri: null,
      });
    } else {
      navigate("/");
    }
  };

  const [loggedInErrorText] = useState<string>(
    "If you are logged in with a different Microsoft account which is not authorized to access this site, please click the below button to clear your session and login again with the correct account."
  );
  const [loggedInButtonText] = useState<string>("Clear Session");

  const [notLoggedInErrorText] = useState<string>(
    "You do not have sufficient privileges to access the portal."
  );
  const [notLoggedInButtonText] = useState<string>("Login");

  return (
    <Styled401Container>
      <Styled401Div>
        <StyledFlexRowDiv>
          <StyledTitle data-testid="heading-text">Unauthorized</StyledTitle>
        </StyledFlexRowDiv>
        <StyledFlexRowDiv>
          <StyledText data-testid="sub-heading-text">
            {!loggedIn ? notLoggedInErrorText : loggedInErrorText}
          </StyledText>
        </StyledFlexRowDiv>
        <StyledFlexRowDiv>
          <button data-testid="home-button" onClick={handleHomeKeyPress}>
            {loggedIn ? loggedInButtonText : notLoggedInButtonText}
          </button>
        </StyledFlexRowDiv>
      </Styled401Div>
      <LocationTestDiv data-testid="location">
        {location.pathname}
      </LocationTestDiv>
    </Styled401Container>
  );
};

export default PageUnauthorized;

const Styled401Container = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 20px 16px 20px 16px;
  height: calc(100vh - 40px);
  height: calc(100dvh - 40px);
  background-color: white;
  display: flex;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 60px 20px 20px 20px;
    width: Calc(100% - 40px);
    height: calc(100dvh - 80px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const LocationTestDiv = styled.div`
  display: none;
`;

const Styled401Div = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    background-color: white;
    border: 1px solid #9c1b30;
    color: #9c1b30;
    border-radius: 3px;
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
    padding: 20px 20px 20px 25px;
    margin-top: 30px;
    width: 150px;

    :hover {
      cursor: pointer;
      background-color: #9c1b30;
      color: white;
    }
  }
`;

const StyledFlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledTitle = styled.h1`
  font-weight: 600;
`;

const StyledText = styled.h2`
  font-weight: 600;
`;
