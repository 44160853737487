import React from "react";
import styled from "styled-components";

interface DropdownProps {
  dataTestname: string;
  columns: [string, string][];
  onchange?: Function;
  selected?: string;
  labelText: string;
  errors?: string;
  required?: boolean;
  hideErrorsDiv?: boolean;
  height?: string;
  textColor?: string;
  border?: string;
  borderRadius?: string;
  readOnly?: boolean;
  padding?: string;
  tabIndex?: number;
  autoFocus?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  dataTestname,
  columns,
  onchange,
  selected,
  labelText,
  errors,
  required,
  hideErrorsDiv,
  height,
  textColor,
  border,
  borderRadius,
  readOnly = false,
  padding = "0px 13px 0px 12px",
  tabIndex,
  autoFocus = false,
}) => {
  return (
    <StyledSelectDiv data-testname={dataTestname}>
      {labelText ? (
        required ? (
          <StyledInputLabel data-testid="dd-label">
            {labelText}
            <StyledAsteriskSpan>&nbsp;*</StyledAsteriskSpan>
          </StyledInputLabel>
        ) : (
          <StyledInputLabel data-testid="dd-label">
            {labelText}
          </StyledInputLabel>
        )
      ) : (
        <></>
      )}
      <StyledSelect
        disabled={readOnly}
        data-testid="dd-select"
        key={columns.length === 0 ? "null" : columns[0][0]?.toString()}
        onChange={(e) => onchange!(e.target.value)}
        value={selected === null ? undefined : selected}
        height={height!}
        textColor={textColor!}
        border={border!}
        borderRadius={borderRadius!}
        required={required}
        readOnly={readOnly}
        padding={padding}
        tabIndex={tabIndex ? tabIndex : undefined}
        autoFocus={autoFocus}
      >
        {columns!.map((column, index) => (
          <option
            data-testid="dd-option"
            data-testname={`${dataTestname + "-" + column}`}
            key={column[0] + index}
            value={column[1].toString()}
          >
            {column[0]?.toString()}
          </option>
        ))}
      </StyledSelect>
      {!hideErrorsDiv ? (
        <StyledErrorMessage
          data-testid="dd-errors"
          errorExists={errors !== undefined && errors !== ""}
        >
          {errors}
        </StyledErrorMessage>
      ) : (
        <></>
      )}
    </StyledSelectDiv>
  );
};

export default Dropdown;

const StyledSelectDiv = styled.div`
  width: 100%;
  text-align: left;
`;

const StyledInputLabel = styled.label`
  text-align: left;
  display: flex;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin-bottom: 6px;
`;

const StyledAsteriskSpan = styled.span`
  color: #9c1b30;
`;

interface SelectProps {
  height: string;
  textColor: string;
  border: string;
  borderRadius: string;
  readOnly: boolean;
  padding: string;
}

const StyledSelect = styled.select<SelectProps>`
  background-color: ${(props) =>
    props.readOnly ? props.theme.disabledBG : "white"};
  width: Calc(100%);
  height: ${(props) => (props.height ? props.height : "30px")};
  color: ${(props) =>
    props.readOnly
      ? props.theme.disabledFont
      : props.textColor
      ? props.textColor
      : "black"};
  border: ${(props) => (props.border ? props.border : "1px solid #e7e9e9")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0px"};
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  padding: ${(props) => props.padding};
  -webkit-padding-start: 8px;
  :focus,
  :active {
    box-shadow: none;
    outline: none;
    border: 1px solid #9c1b30;
    border-radius: 3px;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding: 0px 13px 0px 15px;
    height: ${(props) => (props.height ? props.height : "40px")};
  }
`;

interface ErrorProps {
  errorExists: boolean;
}

const StyledErrorMessage = styled.div<ErrorProps>`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};
  padding-bottom: 10px;

  width: 100%;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding-bottom: ${(props) => (props.errorExists ? "0px" : "20px")};
  }
`;
